import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";

const isNomineeBelow18 = (nomineeDateOfBirth) => {
  // Implement your logic to check if the nominee is below 18 years old
  const dob = new Date(nomineeDateOfBirth);
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();

  // console.log("age", age);
  return age;
};

const isGuardianRequired = (relationShip) => {
  // Implement your logic to check if guardian is required based on the relationship
  const requiredRelationships = [
    "Son",
    "Daughter",
    "Grand son",
    "Grand daughter",
    "Others",
    "Brother",
    "Sister",
  ];
  return (
    relationShip.length > 0 &&
    requiredRelationships.includes(relationShip[0].value)
  );
};

export const schema = Yup.object().shape({
  nominees: Yup.array().of(
    Yup.object().shape({
      nomineeFullName: Yup.string()
        .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
        .required("Nominee Name is Required")
        .max(50)
        .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
        .transform((value: any) => value && value.toUpperCase()),

      nomineeEmail: Yup.string()
        .required("Email is Required")
        .max(50)
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Enter Valid Email"
        ),
      // nomineeDateofBirth: Yup.string()
      //   .required("Nominee Date of Birth is a required")
      //   .test((value: any) => {
      //     if (!value) return true; // Let the required validation handle empty values

      //     const currentDate: any = new Date();
      //     console.log("Current Date:", currentDate);

      //     const dob: any = new Date(value);
      //     console.log("DOB:", dob);

      //     const ageInYears =
      //       (currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000); // Age in years
      //     console.log("Age in Years:", ageInYears);

      //     return ageInYears <= 100;
      //   }),

      nomineeDateofBirth: Yup.string()
        .required("Nominee Date of Birth is required")
        .test({
          name: "valid-age",
          message: "Age must be between 1 and 100",
          test: function (value) {
            if (!value) return true; // Let the required validation handle empty values

            const currentDate: any = new Date();
            const dob: any = new Date(value);

            if (isNaN(dob)) {
              return this.createError({
                message: "Enter Valid Date", // Custom error message for invalid date
                path: "nomineeDateofBirth", // Field name
              });
            }

            const ageInYears =
              (currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000); // Age in years

            return ageInYears >= 0 && ageInYears <= 100;
          },
        }),

      nomineeMobileNumber: Yup.string()
        .required("Mobile Number is Required")
        .max(10)

        .matches(/^[0-9]{10}$/, "Enter Valid Number"),

      percentageofShare: Yup.string()
        .required("Percentage is Required")
        .max(3)
        .matches(/^[0-9]+%*$/, "Enter Valid Percentage"),
      nomineeAddress: Yup.string()
        .required("Address is Required")
        // .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
        .max(250)
        .min(3, "Minimum 10 characters is required"),
      nomineeAddressPinCode: Yup.string()
        .required("Pincode is Required")
        .max(6)
        .min(6, "Minimum 6 numbers is required"),
      nomineeAddressCity: Yup.string()
        .required("City is Required")
        .min(3, "Minimum 3 characters is required")
        .max(50)
        ,
      nomineeAddressState: Yup.object().required("State is Required"),

      relationShip: Yup.object().required("Please select an option"),
      relationshipIfOthers: Yup.string().when(
        "relationShip",
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            relationShip.length &&
            relationShip[0] &&
            relationShip[0].value === "Others"
          ) {
            return schema
              .required("Relationship is Required")
              .matches(/^[a-zA-Z ]*$/, "Enter Valid Relation")
              .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .max(50)
              .transform((value: any) => value && value.toUpperCase());
          }

          return schema;
        }
      ),

      gaurdianFullName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
        .max(50)
        .when(
          ["relationShip", "nomineeDateofBirth"],
          (relationShip: any, schema: any) => {
            //  console.log("relationShip", relationShip);
            if (
              isNomineeBelow18(relationShip[1]) < 18 &&
              isGuardianRequired(relationShip)
            ) {
              return schema
                .required("Please Enter Guardian Name")
                .matches(
                  /^(?!.*\s{2,}).*$/,
                  "Consecutive spaces are not allowed"
                )
                .matches(/^[a-zA-Z ]*$/, "Enter Valid Relation");
              // .transform((value: any) => value && value.toUpperCase());
            }
          }
        ),
      gaurdianDateofBirth: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Guardian Date of Birth is a required")
              .test({
                name: "valid-age",
                message: "Age must be above 18 years",
                test: function (value: any) {
                  if (!value) return true; // Let the required validation handle empty values

                  const currentDate: any = new Date();
                  const dob: any = new Date(value);

                  if (isNaN(dob)) {
                    return this.createError({
                      message: "Enter Valid Date", // Custom error message for invalid date
                      path: "gaurdianDateofBirth", // Field name
                    });
                  }

                  const ageInYears =
                    (currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000); // Age in years

                  return ageInYears >= 18 && ageInYears <= 100;
                },
              });
          }
        }
      ),

      gaurdianEmail: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Enter Valid Email"
        )
        .max(50)
        .when(
          ["relationShip", "nomineeDateofBirth"],
          (relationShip: any, schema: any) => {
            // console.log("relationShip", relationShip);
            if (
              isNomineeBelow18(relationShip[1]) < 18 &&
              isGuardianRequired(relationShip)
            ) {
              return schema.required("Guardian Email is required");
            }
          }
        ),

      gaurdianMobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Enter Valid Number")
        .when(
          ["relationShip", "nomineeDateofBirth"],
          (relationShip: any, schema: any) => {
            // console.log("relationShip", relationShip);
            if (
              isNomineeBelow18(relationShip[1]) < 18 &&
              isGuardianRequired(relationShip)
            ) {
              return schema.required("Guardian PhoneNumber is required");
            }
          }
        ),

      gaurdianRelationWithNominee: Yup.object().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema.required(
              "Guardian Relation With Nominee is required"
            );
          }
        }
      ),
      gaurdianRelationOthers: Yup.string().when(
        ["gaurdianRelationWithNominee", "nomineeDateofBirth"],
        (gaurdianRelationWithNominee: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            // isNomineeBelow18(relationShip[1]) < 18 &&
            // isGuardianRequired(relationShip) &&
            gaurdianRelationWithNominee.length &&
            gaurdianRelationWithNominee[0] &&
            gaurdianRelationWithNominee[0].value === "Others"
          ) {
            return schema
              .required("Relationship Others is Required")
              .matches(/^[a-zA-Z ]*$/, "Enter Valid Relation")
              .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .max(50)
              .transform((value: any) => value && value.toUpperCase());
          }
        }
      ),
      gaurdianAddress: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Guardian Address is required")
              .max(250)
              // .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .min(3, "Minimum 10 characters is required");
          }
        }
      ),
      gaurdianAddressPinCode: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Guardian PinCode is required")
              .max(6)
              .min(6, "Minimum 6 numbers is required");
          }
        }
      ),
      gaurdianAddressCity: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Guardian City is required")
              // .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .min(3, "Minimum 3 characters is required")
              .max(50);
          }
        }
      ),
      gaurdianAddressState: Yup.object().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip: any, schema: any) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema.required("Guardian State is required");
          }
        }
      ),

      nomineeIDProof: Yup.object().required("Nominee Id Proof is required"),
      nomineeIDProofNo: Yup.string()
        .required("Nominee ID Proof Number is required")
        .when("nomineeIDProof", (nomineeIDProof: any, schema: any) => {
          // console.log("nomineeIDProofformik",nomineeIDProof)
          if (nomineeIDProof[0]?.value === "Aadhaar Card")
            return schema
              .required("Nominee ID Proof is required")
              .length(4, "Enter only last 4 numbers of your Aadhaar")
              .matches(/^[0-9]{4}$/, "Enter last 4 numbers of your Aadhaar")
              .transform((value: any) => value && value.toUpperCase());

          if (nomineeIDProof[0]?.value === "Pan Card")
            return schema
              .required("Nominee ID Proof is required")
              .length(10, "Enter Valid Pan Number")
              .matches(
                /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                "Enter Valid Pan Number"
              )
              .transform((value: any) => value && value.toUpperCase());

          return schema;
        }),
      // nomineePanCardNo: Yup.string().when("nomineeIDProof", {
      //   is: "panCardNo",
      //   then(schema) {
      //     return schema.required(" Nominee ID Number is a required");
      //   },
      // }),
    })
  ),
});
function dayjs(arg0: string): any {
  throw new Error("Function not implemented.");
}