import Styles from "../../pages/Styles/NomineeStyles.module.scss";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  Grid,
  FormHelperText,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  RadioGroupProps,
  TextFieldProps,
  OutlinedTextFieldProps,
  ButtonProps,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import calenderImage from "../../Components/assets/Images/calendar.svg";
import NomineeBar from "./NomineeBar";
import SelectProof from "./SelectProof";
import { ISelectProofProps, INomineeFormProps } from "../../shared/dtos";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import DropdownComp from "./DropDownComp";
import DatePickerComp from "./DatePickerComp";
import { nomineeIDDropDown } from "../../shared/commonData";
import { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

function DateIcon(props) {
  return <img src={calenderImage} style={{ width: "25px" }} />;
}

const maxDate = dayjs().subtract(99, "year");
const eighteenYearsAgo = dayjs().subtract(18, "year");
const todayDate = dayjs();

export default function NomineeForm({
  nominee,
  errorData,
  touchedData,
  index,
  currentIndex,
  handleChangeField,
  setFieldValue,
  values,
  handleBlur,
  relationError,
  ageError,
  relationShipData,
  handleNomineeDelete,
  handleNomineeEdit,
  setErrors,
  errors,
  address,
  gaurdianData,
  statesData,
}: INomineeFormProps) {
  const [disableStateField, setDisableStateField] = useState({
    id: "",
    sequence: "",
    value: "",
  });
  const updatedRelationData = relationShipData.filter(
    (obj) => obj.value !== "Others"
  );

  const SelectedStateNew =
    statesData &&
    statesData?.filter((data: any) => data.label === address.State.label)[0];
  const handleSetAddress = (
    values: any,
    callBack: any,
    index: any,
    value: any
  ) => {
    //  console.log("values", values);
    const SelectedState =
      statesData &&
      statesData?.filter(
        (data: any) => data.value.toUpperCase() === address.State
      )[0];
    // console.log("SelectedState", SelectedState, statesData,address.State);
    const nomineeCopy: any = [...values.nominees];
    nomineeCopy[index]["nomineeAddress"] = value ? address.address : "";
    nomineeCopy[index]["nomineeAddressCity"] = value ? address.city : "";
    nomineeCopy[index]["nomineeAddressPinCode"] = value ? address.pincode : "";
    nomineeCopy[index]["nomineeAddressState"] = value ? SelectedState : "";
    callBack("nominees", nomineeCopy);
  };
  const handleSetGaurdianAddress = (
    values: any,
    callBack: any,
    index: any,
    value: any
  ) => {
    const SelectedStateGaurdian =
      statesData &&
      statesData?.filter(
        (data: any) => data.value.toUpperCase() === address.State
      )[0];
    setDisableStateField(SelectedStateGaurdian);
    // console.log("SelectedState", SelectedStateGaurdian, address.State.label);
    const nomineeCopyguardian: any = [...values.nominees];
    nomineeCopyguardian[index]["gaurdianAddress"] = value
      ? address.address
      : "";
    nomineeCopyguardian[index]["gaurdianAddressCity"] = value
      ? address.city
      : "";
    nomineeCopyguardian[index]["gaurdianAddressPinCode"] = value
      ? address.pincode
      : "";
    nomineeCopyguardian[index]["gaurdianAddressState"] = value
      ? SelectedStateGaurdian
      : "";
    callBack("nominees", nomineeCopyguardian);
  };

  const handleDelete = () => {
    handleNomineeDelete(values, setFieldValue, index);
  };
  const handleEdit = () => {
    handleNomineeEdit(index);
  };

  const percentageTotal = values.nominees
    .map((a: any) =>
      a.percentageofShare === "" ? 0 : parseFloat(a.percentageofShare)
    )
    .reduce((sum: any, current: any) => sum + current, 0);
  // console.log("percentageTotal + percentageTotal", percentageTotal);
  const percentageTotalError =
    percentageTotal === 100
      ? ""
      : percentageTotal > 100
      ? "Total Percentage of Share cannot be above 100"
      : "Total Percentage of Share cannot be below 100";
  // console.log("errorData", errorData);
  // console.log("touchedData", touchedData);
  const check18YearOfAge = (dob: any) => {
    if (dob) {
      const dobLimit = new Date();
      dobLimit.setFullYear(dobLimit.getFullYear() - 18);
      if (dob > dobLimit) {
        return false;
      }
      return true;
    }
    return true;
  };
  const dateCheck = nominee.relationShip
    ? nominee.relationShip.guardian_req
      ? todayDate
      : eighteenYearsAgo
    : eighteenYearsAgo;
  const dateCheckError = nominee.relationShip
    ? !nominee.relationShip.guardian_req
      ? check18YearOfAge(nominee.nomineeDateofBirth)
        ? ""
        : "Age should be above 18 years"
      : ""
    : "";

  return (
    <>
      <div className={Styles.leftSubContainer}>
        <div>
          <NomineeBar
            index={index}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            percentageofShare={nominee.percentageofShare}
            currentIndex={currentIndex}
          />
          <div
            style={{
              display: currentIndex !== index ? "none" : "block",
            }}
            className={Styles.fieldsSection}
          >
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee Full Name</p>
                <div>
                  <TextField
                    className={Styles.textfields}
                    id="outlined-basic"
                    placeholder="Nominee's Full name"
                    variant="outlined"
                    name="nomineeFullName"
                    value={nominee.nomineeFullName}
                    error={
                      touchedData.nomineeFullName &&
                      !nominee.nomineeFullName &&
                      errorData &&
                      Boolean(errorData["nomineeFullName"])
                    }
                    // helperText={
                    //   touchedData.nomineeFullName &&
                    //   errorData &&
                    //   errorData["nomineeFullName"]
                    // }
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeFullName",
                        event.target.value.toUpperCase(),
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                      maxLength: 50,
                    }}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                  />
                  <FormHelperText
                    style={{ color: "#ef5350", marginLeft: "15px" }}
                  >
                    {touchedData.nomineeFullName &&
                      errorData &&
                      errorData["nomineeFullName"]}
                  </FormHelperText>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Relationship</p>
                <DropdownComp
                  LabelName="Relationship"
                  menuItems={relationShipData}
                  name="relationShip"
                  value={nominee.relationShip}
                  handleChange={(event: any) => {
                    handleChangeField(
                      "relationShip",
                      event.target.value,
                      setFieldValue,
                      index,
                      values,
                      setErrors,
                      errors
                    );
                  }}
                  onBlur={handleBlur}
                  error={
                    touchedData.relationShip &&
                    errorData &&
                    Boolean(errorData["relationShip"])
                  }
                  helperText={
                    touchedData.relationShip &&
                    errorData &&
                    errorData["relationShip"]
                  }
                  //   setOthers={setOccupationOthers}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Percentage Share</p>
                <div>
                  <TextField
                    className={Styles.textfields}
                    id="outlined-basic"
                    placeholder="Percentage of Share"
                    variant="outlined"
                    name="percentageofShare"
                    value={nominee.percentageofShare}
                    error={
                      touchedData.percentageofShare &&
                      errorData &&
                      Boolean(errorData["percentageofShare"])
                    }
                    helperText={
                      touchedData.percentageofShare &&
                      errorData &&
                      errorData["percentageofShare"]
                    }
                    onChange={(event) => {
                      handleChangeField(
                        "percentageofShare",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                    inputProps={{
                      maxLength: 3,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                        const value: any = event.target.value;
                        if (value > 100) {
                          event.target.value = "100";
                        }
                      },
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                    }}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const isValidKey = /^\d+$/.test(keyValue);

                      if (!isValidKey) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <FormHelperText style={{ color: "#d32f2f" }}>
                    {touchedData.percentageofShare &&
                      percentageTotal > 100 &&
                      "Percenatge of Share should not exceed 100%"}
                  </FormHelperText>
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee Date of Birth</p>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <DemoContainer components={["DatePicker"]}> */}
                    <DatePicker
                      minDate={maxDate}
                      maxDate={dateCheck}
                      disableFuture
                      components={{
                        OpenPickerIcon: DateIcon,
                      }}
                      id="dateofbirthNomineeDateofBirth"
                      placeholder="Nominee Date of Birth"
                      name="nomineeDateofBirth"
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      value={nominee.nomineeDateofBirth}
                      className={Styles.textfields}
                      onChange={(date) => {
                        //console.log("date", date);
                        handleChangeField(
                          "nomineeDateofBirth",
                          date,
                          setFieldValue,
                          index,
                          values,
                          setErrors,
                          errors
                        );
                      }}
                      sx={{
                        borderRadius: "10px",
                        // width: "-webkit-fill-available",
                        background: "#F2F2F6",
                        outline: "none",
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "0 0px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      onBlur={handleBlur}
                      error={
                        (touchedData.nomineeDateofBirth &&
                          errorData &&
                          Boolean(errorData["nomineeDateofBirth"])) ||
                        dateCheckError
                      }
                      helperText={
                        (touchedData.nomineeDateofBirth &&
                          errorData &&
                          errorData["nomineeDateofBirth"]) ||
                        dateCheckError
                      }
                      renderInput={(params: any) => (
                        <TextField {...params} fullWidth />
                      )}
                      // views={undefined}
                      {...(DatePicker as any)}
                    />
                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "13px" }}
                  >
                    {(touchedData.nomineeDateofBirth &&
                      errorData &&
                      errorData["nomineeDateofBirth"]) ||
                      dateCheckError}
                  </FormHelperText>
                </div>
              </Grid>
              {nominee.relationShip.value === "Others" && (
                <Grid item xs={12} sm={4}>
                  <p className={Styles.textfieldLabel}>RelationShip Others</p>
                  <div>
                    <TextField
                      fullWidth
                      className={Styles.textfieldsOthers}
                      id="outlined-basic"
                      placeholder="RelationShip Others"
                      variant="outlined"
                      name="relationshipIfOthers"
                      value={nominee.relationshipIfOthers.toUpperCase()}
                      error={
                        touchedData.relationshipIfOthers &&
                        errorData &&
                        Boolean(errorData["relationshipIfOthers"])
                      }
                      helperText={
                        touchedData.relationshipIfOthers &&
                        errorData &&
                        errorData["relationshipIfOthers"]
                      }
                      onChange={(event) => {
                        handleChangeField(
                          "relationshipIfOthers",
                          event.target.value,
                          setFieldValue,
                          index,
                          values,
                          setErrors,
                          errors
                        );
                      }}
                      onBlur={handleBlur}
                      inputProps={{
                        sx: {
                          background: "#F2F2F6",
                          borderRadius: "8px",
                          "&::placeholder": {
                            paddingLeft: "10px",
                          },

                          paddingLeft: "10px",
                        },
                        maxLength: 50,
                      }}
                      sx={{
                        fieldset: { borderColor: "transparent" },
                        width: { xs: "100%", sm: "100%" },
                        input: {
                          color: "#344054",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </div>
                </Grid>
              )}

              {nominee.relationShip.guardian_req &&
                !check18YearOfAge(nominee.nomineeDateofBirth) && (
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>Guardian's Full Name</p>
                    <div>
                      <TextField
                        fullWidth
                        className={Styles.textfields}
                        id="outlined-basic"
                        placeholder="Guardian's Full Name"
                        variant="outlined"
                        name="gaurdianFullName"
                        value={nominee.gaurdianFullName}
                        error={
                          touchedData.gaurdianFullName &&
                          errorData &&
                          Boolean(errorData["gaurdianFullName"])
                        }
                        helperText={
                          touchedData.gaurdianFullName &&
                          errorData &&
                          errorData["gaurdianFullName"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianFullName",
                            event.target.value.toUpperCase(),
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 50,
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                  </Grid>
                )}
              {nominee.relationShip.guardian_req &&
                !check18YearOfAge(nominee.nomineeDateofBirth) && (
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>
                      Guardian's Date of Birth
                    </p>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture
                          components={{
                            OpenPickerIcon: DateIcon,
                          }}
                          minDate={maxDate}
                          maxDate={eighteenYearsAgo}
                          views={["year", "month", "day"]}
                          id="dateofbirthGaurdianDateofBirth"
                          placeholder="Guardian Date of Birth"
                          name="gaurdianDateofBirth"
                          format="DD/MM/YYYY"
                          value={
                            nominee.gaurdianDateofBirth === ""
                              ? null
                              : nominee.gaurdianDateofBirth
                          }
                          className={Styles.datepicker}
                          onChange={(date) => {
                            //console.log("date", date);
                            handleChangeField(
                              "gaurdianDateofBirth",
                              date,
                              setFieldValue,
                              index,
                              values,
                              setErrors,
                              errors
                            );
                          }}
                          sx={{
                            borderRadius: "10px",
                            // width: "-webkit-fill-available",
                            background: "#F2F2F6",
                            outline: "none",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "0 0px",
                            color: "#000000",
                            boxShadow: "none",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          onBlur={handleBlur}
                          error={
                            touchedData.gaurdianDateofBirth &&
                            errorData &&
                            Boolean(errorData["gaurdianDateofBirth"])
                          }
                          helperText={
                            touchedData.gaurdianDateofBirth &&
                            errorData &&
                            errorData["gaurdianDateofBirth"]
                          }
                          renderInput={(params: any) => (
                            <TextField {...params} />
                          )}
                          {...(DatePicker as any)}
                        />
                      </LocalizationProvider>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "13px" }}
                      >
                        {errorData && errorData["gaurdianDateofBirth"]}
                      </FormHelperText>
                    </div>
                  </Grid>
                )}

              {nominee.relationShip.guardian_req &&
                !check18YearOfAge(nominee.nomineeDateofBirth) && (
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>Guardian's Email</p>
                    <div>
                      <TextField
                        fullWidth
                        className={Styles.textfields}
                        id="outlined-basic"
                        placeholder="Guardian's Email"
                        variant="outlined"
                        name="gaurdianEmail"
                        value={nominee.gaurdianEmail}
                        error={
                          touchedData.gaurdianEmail &&
                          errorData &&
                          Boolean(errorData["gaurdianEmail"])
                        }
                        helperText={
                          touchedData.gaurdianEmail &&
                          errorData &&
                          errorData["gaurdianEmail"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianEmail",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 50,
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                  </Grid>
                )}
              {nominee.relationShip.guardian_req &&
                !check18YearOfAge(nominee.nomineeDateofBirth) && (
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>
                      Guardian's Mobile Number
                    </p>
                    <div>
                      <TextField
                        fullWidth
                        className={Styles.textfields}
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Guardian Mobile"
                        name="gaurdianMobileNumber"
                        value={nominee.gaurdianMobileNumber}
                        error={
                          touchedData.gaurdianMobileNumber &&
                          errorData &&
                          Boolean(errorData["gaurdianMobileNumber"])
                        }
                        helperText={
                          touchedData.gaurdianMobileNumber &&
                          errorData &&
                          errorData["gaurdianMobileNumber"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianMobileNumber",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const isValidKey = /^\d+$/.test(keyValue);

                          if (!isValidKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </Grid>
                )}
              {nominee.relationShip.guardian_req &&
                !check18YearOfAge(nominee.nomineeDateofBirth) && (
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>
                      Guardian Relation with Nominee
                    </p>
                    <div className={Styles.textfields}>
                      <DropdownComp
                        LabelName="Guardian Relation with Nominee"
                        menuItems={relationShipData}
                        name="gaurdianRelationWithNominee"
                        value={nominee.gaurdianRelationWithNominee}
                        handleChange={(event: any) => {
                          handleChangeField(
                            "gaurdianRelationWithNominee",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        error={
                          touchedData.gaurdianRelationWithNominee &&
                          errorData &&
                          Boolean(errorData["gaurdianRelationWithNominee"])
                        }
                        helperText={
                          touchedData.gaurdianRelationWithNominee &&
                          errorData &&
                          errorData["gaurdianRelationWithNominee"]
                        }
                        //   setOthers={setOccupationOthers}
                      />
                    </div>
                  </Grid>
                )}
              {nominee.relationShip.guardian_req &&
                !check18YearOfAge(nominee.nomineeDateofBirth) &&
                nominee.gaurdianRelationWithNominee.value === "Others" && (
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>
                      Guardian Relationship Others
                    </p>
                    <div className={Styles.textfields}>
                      <TextField
                        fullWidth
                        className={Styles.addressfields}
                        id="outlined-basic"
                        placeholder="Guardian Relationship Others"
                        variant="outlined"
                        name="gaurdianRelationOthers"
                        value={
                          nominee.gaurdianRelationOthers
                            ? nominee.gaurdianRelationOthers
                            : ""
                        }
                        // InputLabelProps={{ shrink: true }}
                        error={
                          touchedData.gaurdianRelationOthers &&
                          errorData &&
                          Boolean(errorData["gaurdianRelationOthers"])
                        }
                        helperText={
                          touchedData.gaurdianRelationOthers &&
                          errorData &&
                          errorData["gaurdianRelationOthers"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianRelationOthers",
                            event.target.value.toUpperCase(),
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 50,
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                        disabled={false}
                        // disabled={address.address === nominee.gaurdianAddress}
                      />
                    </div>
                  </Grid>
                )}
            </Grid>
            {nominee.relationShip.guardian_req &&
              !check18YearOfAge(nominee.nomineeDateofBirth) && (
                <div className={Styles.checkBox}>
                  <Checkbox
                    checked={address.address === nominee.gaurdianAddress}
                    onChange={(event) => {
                      handleSetGaurdianAddress(
                        values,
                        setFieldValue,
                        index,
                        event.target.checked
                      );
                    }}
                  />
                  <p className={Styles.checkboxlabel}>
                    Make Guardian address same as mine
                  </p>
                </div>
              )}
            {nominee.relationShip.guardian_req &&
              !check18YearOfAge(nominee.nomineeDateofBirth) && (
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                >
                  <Grid item xs={12} sm={8}>
                    <p className={Styles.textfieldLabel}>Guardian Address</p>
                    <div>
                      <TextField
                        fullWidth
                        className={Styles.addressfields}
                        id="outlined-basic"
                        placeholder="Guardian Address"
                        variant="outlined"
                        name="gaurdianAddress"
                        value={
                          nominee.gaurdianAddress ? nominee.gaurdianAddress : ""
                        }
                        // InputLabelProps={{ shrink: true }}
                        error={
                          touchedData.gaurdianAddress &&
                          errorData &&
                          Boolean(errorData["gaurdianAddress"])
                        }
                        helperText={
                          touchedData.gaurdianAddress &&
                          errorData &&
                          errorData["gaurdianAddress"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianAddress",
                            event.target.value.toUpperCase(),
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 250,
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                        disabled={false}
                        // disabled={address.address === nominee.gaurdianAddress}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>Guardian Pincode</p>
                    <div>
                      <TextField
                        fullWidth
                        className={Styles.textfields}
                        id="outlined-basic"
                        placeholder="Guardian Pincode"
                        variant="outlined"
                        name="gaurdianAddressPinCode"
                        value={
                          nominee.gaurdianAddressPinCode
                            ? nominee.gaurdianAddressPinCode
                            : ""
                        }
                        // InputLabelProps={{ shrink: true }}
                        error={
                          touchedData.gaurdianAddressPinCode &&
                          errorData &&
                          Boolean(errorData["gaurdianAddressPinCode"])
                        }
                        helperText={
                          touchedData.gaurdianAddressPinCode &&
                          errorData &&
                          errorData["gaurdianAddressPinCode"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianAddressPinCode",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        disabled={false}
                        // disabled={
                        //   address.pincode === nominee.gaurdianAddressPinCode
                        // }
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 6,
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const isValidKey = /^\d+$/.test(keyValue);

                          if (!isValidKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>Guardian City</p>
                    <div>
                      <TextField
                        fullWidth
                        className={Styles.textfields}
                        id="outlined-basic"
                        placeholder="Guardian City"
                        variant="outlined"
                        name="gaurdianAddressCity"
                        value={
                          nominee.gaurdianAddressCity
                            ? nominee.gaurdianAddressCity
                            : ""
                        }
                        // InputLabelProps={{ shrink: true }}
                        error={
                          touchedData.gaurdianAddressCity &&
                          errorData &&
                          Boolean(errorData["gaurdianAddressCity"])
                        }
                        helperText={
                          touchedData.gaurdianAddressCity &&
                          errorData &&
                          errorData["gaurdianAddressCity"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianAddressCity",
                            event.target.value.toUpperCase(),
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        disabled={false}
                        // disabled={
                        //   address.city === nominee.gaurdianAddressCity
                        // }
                        inputProps={{
                          sx: {
                            background: "#F2F2F6",
                            borderRadius: "8px",
                            "&::placeholder": {
                              paddingLeft: "10px",
                            },

                            paddingLeft: "10px",
                          },
                          maxLength: 50,
                        }}
                        sx={{
                          fieldset: { borderColor: "transparent" },
                          width: { xs: "100%", sm: "100%" },
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <p className={Styles.textfieldLabel}>Guardian State</p>
                    <div>
                      <DropdownComp
                        LabelName="Guardian State"
                        menuItems={statesData}
                        name="gaurdianAddressState"
                        value={
                          nominee.gaurdianAddressState
                            ? nominee.gaurdianAddressState
                            : ""
                        }
                        handleChange={(event: any) => {
                          handleChangeField(
                            "gaurdianAddressState",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        error={
                          touchedData.gaurdianAddressState &&
                          errorData &&
                          Boolean(errorData["gaurdianAddressState"])
                        }
                        helperText={
                          touchedData.gaurdianAddressState &&
                          errorData &&
                          errorData["gaurdianAddressState"]
                        }
                        //   setOthers={setOccupationOthers}
                        disabled={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}

            <p className={Styles.nomineeContactDetails}>
              Nominee Contact Details
            </p>
            <div className={Styles.checkBox}>
              <Checkbox
                checked={address.address === nominee.nomineeAddress}
                onChange={(event) => {
                  handleSetAddress(
                    values,
                    setFieldValue,
                    index,
                    event.target.checked
                  );
                }}
              />
              <p className={Styles.checkboxlabel}>
                Make Nominee address same as mine
              </p>
            </div>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid item xs={12} sm={8}>
                <p className={Styles.textfieldLabel}>Nominee Address</p>
                <div>
                  <TextField
                    fullWidth
                    id="nomineeAddress"
                    className={Styles.addressfields}
                    name="nomineeAddress"
                    variant="outlined"
                    placeholder="Nominee Address"
                    value={nominee.nomineeAddress.toUpperCase()}
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeAddress",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    error={
                      touchedData.nomineeAddress &&
                      errorData &&
                      Boolean(errorData["nomineeAddress"])
                    }
                    helperText={
                      touchedData.nomineeAddress &&
                      errorData &&
                      errorData["nomineeAddress"]
                    }
                    disabled={false}
                    // disabled={address.address === nominee.nomineeAddress}
                    inputProps={{
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                      maxLength: 250,
                    }}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee Pincode</p>
                <div>
                  <TextField
                    fullWidth
                    className={Styles.pincodeField}
                    id="outlined-basic"
                    placeholder="Nominee Pincode"
                    variant="outlined"
                    name="nomineeAddressPinCode"
                    value={nominee.nomineeAddressPinCode}
                    error={
                      touchedData.nomineeAddressPinCode &&
                      errorData &&
                      Boolean(errorData["nomineeAddressPinCode"])
                    }
                    helperText={
                      touchedData.nomineeAddressPinCode &&
                      errorData &&
                      errorData["nomineeAddressPinCode"]
                    }
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeAddressPinCode",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    // disabled={address.pincode === nominee.nomineeAddressPinCode}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const isValidKey = /^\d+$/.test(keyValue);

                      if (!isValidKey) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                      maxLength: 6,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee City</p>
                <div>
                  <TextField
                    fullWidth
                    className={Styles.textfields}
                    id="outlined-basic"
                    placeholder="Nominee City"
                    variant="outlined"
                    name="nomineeAddressCity"
                    value={nominee.nomineeAddressCity.toUpperCase()}
                    error={
                      touchedData.nomineeAddressCity &&
                      errorData &&
                      Boolean(errorData["nomineeAddressCity"])
                    }
                    helperText={
                      touchedData.nomineeAddressCity &&
                      errorData &&
                      errorData["nomineeAddressCity"]
                    }
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeAddressCity",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    // disabled={address.city === nominee.nomineeAddressCity}
                    inputProps={{
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                      maxLength: 50,
                    }}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee State</p>
                <div>
                  <DropdownComp
                    LabelName="Nominee State"
                    menuItems={statesData}
                    name="nomineeAddressState"
                    value={nominee.nomineeAddressState}
                    disabled={false}
                    // disabled={SelectedStateNew === nominee.nomineeAddressState}
                    handleChange={(event: any) => {
                      handleChangeField(
                        "nomineeAddressState",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    error={
                      touchedData.nomineeAddressState &&
                      errorData &&
                      Boolean(errorData["nomineeAddressState"])
                    }
                    helperText={
                      touchedData.nomineeAddressState &&
                      errorData &&
                      errorData["nomineeAddressState"]
                    }
                    //   setOthers={setOccupationOthers}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee Email</p>
                <div>
                  <TextField
                    fullWidth
                    className={Styles.textfields}
                    id="outlined-basic"
                    type="email"
                    placeholder="Nominee's Email"
                    variant="outlined"
                    name="nomineeEmail"
                    value={nominee.nomineeEmail}
                    error={
                      touchedData.nomineeEmail &&
                      errorData &&
                      Boolean(errorData["nomineeEmail"])
                    }
                    helperText={
                      touchedData.nomineeEmail &&
                      errorData &&
                      errorData["nomineeEmail"]
                    }
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeEmail",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                      maxLength: 50,
                    }}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={Styles.textfieldLabel}>Nominee Mobile</p>
                <div>
                  <TextField
                    className={Styles.textfields}
                    id="outlined-basic"
                    placeholder="Nominee's Mobile Number"
                    variant="outlined"
                    name="nomineeMobileNumber"
                    value={nominee.nomineeMobileNumber}
                    error={
                      touchedData.nomineeMobileNumber &&
                      errorData &&
                      Boolean(errorData["nomineeMobileNumber"])
                    }
                    helperText={
                      touchedData.nomineeMobileNumber &&
                      errorData &&
                      errorData["nomineeMobileNumber"]
                    }
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeMobileNumber",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      sx: {
                        background: "#F2F2F6",
                        borderRadius: "8px",
                        "&::placeholder": {
                          paddingLeft: "10px",
                        },

                        paddingLeft: "10px",
                      },
                      maxLength: 10,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    sx={{
                      fieldset: { borderColor: "transparent" },
                      width: { xs: "100%", sm: "100%" },
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                      },
                    }}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const isValidKey = /^\d+$/.test(keyValue);

                      if (!isValidKey) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </Grid>
              {currentIndex === index && (
                <Grid item xs={12} sm={4}>
                  <p className={Styles.textfieldLabel}>
                    Select Your Identity Proof
                  </p>
                  <div>
                    <DropdownComp
                      LabelName="Select Your Identity Proof"
                      menuItems={nomineeIDDropDown}
                      name="nomineeIDProof"
                      value={nominee.nomineeIDProof}
                      disabled={false}
                      // disabled={SelectedStateNew === nominee.nomineeAddressState}
                      handleChange={(event) => {
                        handleChangeField(
                          "nomineeIDProof",
                          event.target.value,
                          setFieldValue,
                          index,
                          values,
                          setErrors,
                          errors
                        );
                      }}
                      onBlur={handleBlur}
                      error={
                        touchedData.nomineeIDProof &&
                        errorData &&
                        Boolean(errorData["nomineeIDProof"])
                      }
                      helperText={
                        touchedData.nomineeIDProof &&
                        errorData &&
                        errorData["nomineeIDProof"]
                      }
                      //   setOthers={setOccupationOthers}
                    />
                  </div>
                </Grid>
              )}
              {currentIndex === index && nominee.nomineeIDProof && (
                <Grid item xs={12} sm={4}>
                  <p className={Styles.textfieldLabel}>Enter ID Proof No</p>
                  <div>
                    <TextField
                      // {...props}
                      className={Styles.proofTextFeild}
                      // fullWidth
                      // size="small"
                      id="outlined-basic"
                      placeholder={nominee.nomineeIDProof.value === "Aadhaar Card"? "Enter Last 4 digits of your Aadhaar":"Enter Pan Number"}
                      variant="outlined"
                      name={"nomineeIDProofNo"}
                      value={nominee.nomineeIDProofNo?.toUpperCase()}
                      error={
                        touchedData.nomineeIDProofNo &&
                        errorData &&
                        Boolean(errorData["nomineeIDProofNo"])
                      }
                      helperText={
                        touchedData.nomineeIDProofNo &&
                        errorData &&
                        errorData["nomineeIDProofNo"]
                      }
                      onChange={(event) => {
                        handleChangeField(
                          "nomineeIDProofNo",
                          event.target.value,
                          setFieldValue,
                          index,
                          values,
                          setErrors,
                          errors
                        );
                      }}
                      onBlur={handleBlur}
                      inputProps={{
                        sx: {
                          background: "#F2F2F6",
                          borderRadius: "8px",
                          "&::placeholder": {
                            paddingLeft: "10px",
                          },

                          paddingLeft: "10px",
                        },
                        maxLength: 50,
                      }}
                      sx={{
                        fieldset: { borderColor: "transparent" },
                        width: { xs: "100%", sm: "100%" },
                        input: {
                          color: "#344054",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </div>
                </Grid>
              )}
            </Grid>

            {/* Last----------------------------------------------------------------- */}
          </div>
        </div>
      </div>
      {/* <SelectProof
        textFieldName={"nomineeIDProofNo"}
        radioGroupValue={nominee.nomineeIDProof}
        textFieldValue={nominee.nomineeIDProofNo?.toUpperCase()}
        textFieldLabel={nominee.noimineeIDProofNo}
        textFieldError={errorData && Boolean(errorData["nomineeIDProofNo"])}
        onTextFieldChange={(event) => {
          handleChangeField(
            "nomineeIDProofNo",
            event.target.value,
            setFieldValue,
            index,
            values,
            setErrors,
            errors
          );
        }}
        textFieldHelperText={errorData && errorData["nomineeIDProofNo"]}
        radioGroups={nomineeIDDropDown}
        index={index}
        currentIndex={currentIndex}
        name={"nomineeIDProof"}
        values={values}
        handleRadioButtonFieldChange={(event) => {
          handleChangeField(
            "nomineeIDProof",
            event.target.value,
            setFieldValue,
            index,
            values,
            setErrors,
            errors
          );
        }}
        helperText={errorData && errorData["nomineeIDProof"]}
        variant={"outlined"}
      /> */}
    </>
  );
}
